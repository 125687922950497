<template>
  <v-dialog v-model="dialog" max-width="500px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        class="my-1 text-none"
        v-bind="attrs"
        v-on="on"
        rounded
      >
        <v-icon left> mdi-plus </v-icon>
        Nuevo Módulo
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-body-1 primary--text">Nuevo Módulo</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="isValidFormDialog" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="editedItem.app_id"
                  :items="applications"
                  :rules="applicationRules"
                  item-value="id"
                  item-text="name"
                  label="Aplicación"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editedItem.name"
                  :rules="nameRules"
                  label="Nombre"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="editedItem.description"
                  name="description"
                  label="Descripción"
                  :rules="descriptionRules"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey lighten-1" text @click="close()"> Cancelar </v-btn>
        <v-btn
          color="blue darken-1"
          :loading="isLoading"
          :disabled="isLoading"
          @click="save()"
          text
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { $EventBus } from "@/main";
export default {
  name: "CreateModuleDialog",

  props: {},

  data: () => ({
    dialog: false,
    isValidFormDialog: false,
    nameRules: [(v) => !!v || "Campo requerido"],
    descriptionRules: [(v) => !!v || "Campo requerido"],
    applicationRules: [(v) => !!v || "Campo requerido"],
    isLoading: false,
    editedItem: {
      name: "",
      description: "",
      active: true,
      app_id: "",
    },
    defaultItem: {
      name: "",
      description: "",
      active: true,
      app_id: "",
    },
  }),

  computed: {
    applications() {
      return this.$store.getters["applications/getApplications"];
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.$refs.form.reset();
      });
    },

    showSnack(color = "", text = "") {
      $EventBus.$emit("showSnack", { color, text });
    },

    async save() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        try {
          await this.$store.dispatch("modules/saveModule", this.editedItem);
          this.$emit('resetFilter');
          this.showSnack("green", "Se ha registrado satisfactoriamente");
        } catch (error) {
          this.showSnack("red", error);
        }
        this.isLoading = false;
        this.close();
      }
    },
  },
};
</script>
